<template>
    <div>
    <!--
        檢查下載檔案數量::checkPackageCount
    -->
    <div class="nicolespace"></div> 
    <div class="text-center">
        <h4>請選擇類型 : </h4>
        <select class="form-control cap" @change="onChangeCheckPackageCountType($event)">
            <option :selected="checkPackageCountType == 'essay'" value="essay">原文朗讀</option>
            <option :selected="checkPackageCountType == 'explanation'" value="explanation">老師詳解</option>
            <option :selected="checkPackageCountType == 'preview'" value="preview">單字預習</option>
            <option :selected="checkPackageCountType == 'sentence'" value="sentence">單字與例句</option>
        </select>  
        <button type="button" class="btn btn-lg  btn-success"  @click="onCheckPackageCount">檢查下載檔案數量</button>        
    </div>
    </div>
</template>


<script>

export default {
    name: 'UnitTest',
    components: {
    },
    data () {
    return { 
            checkPackageCountType:"essay",
        }
    },
    methods: {
        logCallback(data){},
        checkPackageCountCallback(count){
            console.log(`count ==> ${count}`);
            this.$JsBridge.callHandler('log', `count ==> ${count}`, this.logCallback);
        },
        onCheckPackageCount(){
            console.log(`checkPackageCountType ==> ${this.checkPackageCountType}`);
            this.$JsBridge.callHandler('checkPackageCount', this.checkPackageCountType, this.checkPackageCountCallback);
        },
        onChangeCheckPackageCountType(event){
            this.checkPackageCountType = event.target.value;
        },
    },
    mounted() {
    },
    created(){   
    }
}
</script>